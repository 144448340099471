// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import JQuery from 'jquery';
// window.$ = window.JQuery = window.jQuery = JQuery;

import "jquery/dist/jquery"
import "bootstrap/dist/js/bootstrap"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("chart.js")
require("typeahead.js")
require("@nathanvda/cocoon")

require("packs/jquery.serializejson.min.js")
require("packs/global.js.coffee")
require("packs/institutes.js.coffee")
require("packs/search.js.coffee")
require("packs/disambiguates.js.coffee")
require("packs/users.js.coffee")
require("packs/source_data.js.coffee")
require("packs/source_data_upload.js.coffee")
require("packs/freshworks.js.coffee")
require("packs/template_comments.js.coffee")
require("packs/qa_administration.js.coffee")
require("packs/funder_groups.js.coffee")
require("packs/university_groups.js.coffee")
require("packs/system.js.coffee")
require("packs/ror_changes.js.coffee")



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


